import React from 'react'
import { FormattedMessage } from 'react-intl'
import MarkdownMessage from '@gluedigital/markdown-message'

import './Who.sass'

import icex from 'src/static/svg/who/icex.svg'
import eoi from 'src/static/svg/who/eoi-new.svg'
import fse from 'src/static/svg/who/fse+.svg'
import ue from 'src/static/svg/who/ue.svg'
import adigital from 'src/static/svg/who/adigital.svg'

const Who = ({ onView }) => {

  return (
    <section id="who-are" className={onView ? 'onView' : ''}>
      <div className="container">
        <div className="split">
          <div className="item item-left left">
            <h2 className="subtitle">
              <FormattedMessage id="home.general.subtitle" />
            </h2>
            <h1 className="title">
              <FormattedMessage id="home.who-are.title" />
            </h1>
            <p>
              <MarkdownMessage id="home.who-are.text-1" />
            </p>
            <p>
              <MarkdownMessage id="home.who-are.text-2" />
            </p>
          </div>
          <div className="item item-right right">
            <div className="who-are-grid">
              <a href="https://www.icex.es" target="_blank" rel="noopener noreferrer">
                <img src={icex} alt="icex logo" />
              </a>
              <a href="https://www.eoi.es" target="_blank" rel="noopener noreferrer">
                <img src={eoi} alt="eoi logo" className='gray-logo'/>
              </a>
              <div className="inline-group">
                <a href="https://european-union.europa.eu/index_es" target="_blank" rel="noopener noreferrer">
                  <img src={ue} alt="ue logo" className="inline-logo" />
                </a>
                <a href="https://www.fondoseuropeos.hacienda.gob.es/sitios/dgfc/es-ES/Paginas/FSE.aspx" target="_blank" rel="noopener noreferrer">
                  <img src={fse} alt="fse+ logo" className="inline-logo" />
                </a>
              </div>
              <a href="https://www.adigital.org/" target="_blank" rel="noopener noreferrer" className='adigital-logo-container'>
                <img src={adigital} alt="adigital logo" className='adigital-logo'/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Who
