import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import data from 'src/data'
import Loading from '../Loading/Loading'

import './Register.sass'

const Register = ({ onView = false }) => {
  const { cities } = data
  const cityNames = cities.map(c => c.name)
  const steps = ['pre-registration', 'preselection', 'interview', 'confirmation']
  const [status, setStatus] = useState('')
  const buyOnline = [
    "Si",
    "No",
    "En proceso"
  ]
  const businessModel = [
    "B2B (Servicio o venta directa a otra empresa)",
    "B2C (Servicio o venta directa al consumidor)",
    "B2B2C (Tanto servicio o venta a otra empresa, como directamente al consumidor)"
  ]

  const intl = useIntl()

  const fetchApiSubmit = async (event) => {
    event.preventDefault()
    if (status === 'loading') return
    setStatus('loading')
    try {
      const res = await fetch("/api/submit", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          contactStatus: event.target["formHow"].value,
          contactSurnames: event.target["formLastName"].value,
          contactName: event.target["formName"].value,
          contactPhone: event.target["formPhone"].value,
          contactEmail: event.target["formMail"].value,
          contactPosition: event.target["formJob"].value,
          companyName: event.target["formCompany"].value,
          companySector: event.target["formNIF"].value,
          contactCity: event.target["formCity"].value,
          contactExperience: event.target["formCompanyPhrase"].value,
          companyWeb: event.target["formWeb"].value,
          companyBilling: event.target["formBilling"].value,
          companyExport: event.target["formExport"].value,
          companyEmployees: event.target["formEmployees"].value,
          companyExportCountries: event.target["formExportCountries"].value,
          canBuyOnline: event.target["formCanBuyOnline"].value,
          businessModel: event.target["formBusinessModel"].value,
          attendingCity: event.target["formAttendingCity"].value,
          contactComments: event.target["formComments"].value
        })
      })
      console.log(res.status)
      setStatus('success')
    } catch (err) {
      console.error(err)
      setStatus('error')
    }
  }

  const form =
    <form onSubmit={fetchApiSubmit}>
      <h1 className="title">
        <FormattedMessage id="register.title" />
      </h1>
      <span className='register_subtitle'><FormattedMessage id="register.subtitle" /></span>

      <div className="row">
        <label>
          <input
            required
            type="text"
            name="formHow"
            autoComplete="organization"
            placeholder={intl.formatMessage({ id: 'register.form-how' })}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formLastName"
            placeholder={intl.formatMessage({ id: 'register.form-lastName' })}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="formName"
            placeholder={intl.formatMessage({ id: 'register.form-name' })}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formPhone"
            placeholder={intl.formatMessage({ id: 'register.form-phone' })}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="formMail"
            placeholder={intl.formatMessage({ id: 'register.form-mail' })}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formJob"
            placeholder={intl.formatMessage({ id: 'register.form-job' })}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="formCompany"
            placeholder={intl.formatMessage({ id: 'register.form-company' })}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formNIF"
            placeholder={intl.formatMessage({ id: 'register.form-nif' })}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="formCity"
            placeholder={intl.formatMessage({ id: 'register.form-city' })}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formCompanyPhrase"
            placeholder={intl.formatMessage({ id: 'register.form-companyPhrase' })}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formWeb"
            placeholder={intl.formatMessage({ id: 'register.form-web' })}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="formBilling"
            placeholder={intl.formatMessage({ id: 'register.form-billing' })}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formExport"
            placeholder={intl.formatMessage({ id: 'register.form-export' })}
          />
        </label>
        <label>
          <input
            type="text"
            required
            name="formEmployees"
            placeholder={intl.formatMessage({ id: 'register.form-employees' })}
          />
        </label>
      </div>

      <div className="row">
        <label>
          <input
            type="text"
            required
            name="formExportCountries"
            placeholder={intl.formatMessage({ id: 'register.form-exportCountries' })}
          />
        </label>
        <label>
          <select required name="formCanBuyOnline">
            <option value="">{intl.formatMessage({ id: 'register.form-canBuyOnline' })}</option>
            {buyOnline.map(c => (
              <option value={c} key={c}>{c}</option>
            ))}
          </select>
        </label>
      </div>

      <div className="row">
        <select required name="formBusinessModel">
          <option value="">{intl.formatMessage({ id: 'register.form-businessModel' })}</option>
          {businessModel.map(c => (
            <option value={c} key={c}>{c}</option>
          ))}
        </select>
        <select required name="formAttendingCity">
          <option value="">{intl.formatMessage({ id: 'register.form-attendingCity' })}</option>
          {cityNames.map(c => (
            <option value={c} key={c}>{c}</option>
          ))}
        </select>
      </div>

      <div className="row">
        <label>
          <textarea
            name="formComments"
            placeholder={intl.formatMessage({ id: 'register.form-comments' })}
          />
        </label>
      </div>

      <p>
        <FormattedMessage
          id="register.text"
          values={{
            privacyPolicy: <a href="/privacy" target="_blank" rel="noopener noreferrer">política de privacidad</a>,
          }}
        />
      </p>
      <div className="row privacy-checkbox">
        <label>
          <input
            type="checkbox"
            id="register_checkbox"
          />
          <FormattedMessage id="register.checkbox" />
        </label>
      </div>

      <button type="submit" className="filled"><FormattedMessage id="send" /></button>
    </form>

  return (
    <section id="register" className={onView ? 'onView' : ''}>
      <header className="header-steps">
        {steps.map((s, i) =>
          <div key={s} className={`header-step step-${s}`}>
            <span className={`icon icon-${s}`} />
            <span className="name"><FormattedMessage id={`register.step-${s}`} /></span>
            {i + 1 !== steps.length && <span className="icon icon-arrow" />}
          </div>
        )}
      </header>
      {status === 'loading'
        ? <Loading />
        : form
      }
    </section>
  )
}

export default Register
