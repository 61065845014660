import React from 'react'
import { FormattedMessage } from 'react-intl'
import logos from './companiesLogos'

import './Companies.sass'

const Companies = ({ onView }) => {

  const companiesLogos = [
    ['microsoft', 'https://www.microsoft.com/'],
    ['google', 'https://www.google.es/'],
    ['aws', 'https://aws.amazon.com/'],
    ['atento', 'https://atento.com/'],
    ['garrigues', 'https://www.garrigues.com/'],
    ['telefonica', 'https://www.telefonica.com/'],
    ['alibaba', 'https://www.alibaba.com/'],
    ['adyen', 'https://www.adyen.com/'],
    ['amvos', 'http://www.amvos.com/'],
    ['biko', 'https://www.biko2.com/'],
    ['binomio_ventures', 'http://binomioventures.com/'],
    ['brainy_commerce', 'https://www.brainycommerce.com/'],
    ['cofnianza_online', 'https://www.confianzaonline.es/'],
    ['cyberclik', 'https://www.cyberclick.es/'],
    ['dhl_express', 'https://mydhl.express.dhl/'],
    ['digital_addiction', 'https://digitaladdiction.es/'],
    ['ebay', 'https://www.ebay.es/'],
    ['good_rebels', 'https://www.goodrebels.com/'],
    ['howdazz', 'https://www.howdazz.com/'],
    ['innova_export', 'https://www.innovaexport.com/'],
    ['interacso', 'https://www.interacso.com/'],
    ['iristrace', 'https://iristrace.com/'],
    ['lideres_digitales', 'https://www.lideresydigitales.com/'],
    ['metaverso_360', 'https://metaverso360.io/'],
    ['paycomet', 'https://www.paycomet.com/'],
    ['people4', 'https://www.people4.es/'],
    ['proportione', 'https://proportione.com/'],
    ['roundcubers', 'https://www.roundcubers.com/'],
    ['sakudarte', 'https://sakudarte.com/'],
    ['sci_the_world', 'https://www.scitheworld.com/'],
    ['sisteplant', 'https://www.sisteplant.com/'],
    ['sngular', 'https://www.sngular.com/'],
    ['ups', 'https://www.ups.com/']
  ]

  return (
    <section id="companies" className={onView ? 'onView' : ''}>
      <div className="container">
        <h1 className="title">
          <FormattedMessage id="home.companies-title" />
        </h1>
        <h2>
          <FormattedMessage id="home.companies-subtitle" />
        </h2>
        <ul className="companies-grid">
          {companiesLogos.map(([company, url]) => (
            <li key={company}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                <img src={logos[company]} alt={`icon ${company}`} />
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  )
}

export default Companies
