import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useAtTop } from '@gluedigital/scrollastic';

import logo from 'src/static/svg/logo-b-new.svg';
import logoBW from 'src/static/svg/logo-bw-new.svg';
import logoW from 'src/static/svg/logo-w-new.svg';

import './Header.sass';

interface HeaderProps {
  scrollable?: any;
  page?: string;
}

const scrollToSection = (id: string) => {
  const section = document.getElementById(id);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth' });
  }
};

const Header = ({ scrollable, page }: HeaderProps) => {
  const atTop = useAtTop(scrollable);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const handleLinkClick = (e: React.MouseEvent, sectionId: string) => {
    e.preventDefault();
    setOpen(false);

    if (location.pathname !== '/') {
      history.push(`/#${sectionId}`);
    } else {
      scrollToSection(sectionId);
    }
  };

  return (
    <header id="masthead" className={`${atTop ? '' : 'active'} ${page}`}>
      <div className="container">
        <nav>
          <img
            className="logo"
            src={open ? logoW : atTop && page === 'home' ? logoBW : logo}
            alt="Nuevos retos logo"
          />
          <button className="menu-button" onClick={() => setOpen(!open)}>
            <span className={`icon icon-${open ? 'cross' : 'menu'}`}></span>
          </button>
          <ul className={`menu ${open ? 'open' : ''}`}>
            <li>
              <a
                href="#hero-banner"
                className="link"
                onClick={(e) => handleLinkClick(e, 'hero-banner')}
              >
                <FormattedMessage id="header.link.home" />
              </a>
            </li>
            <li>
              <a
                href="#who-are"
                className="link"
                onClick={(e) => handleLinkClick(e, 'who-are')}
              >
                <FormattedMessage id="header.link.who" />
              </a>
            </li>
            <li>
              <a
                href="#why-participate"
                className="link"
                onClick={(e) => handleLinkClick(e, 'why-participate')}
              >
                <FormattedMessage id="header.link.why" />
              </a>
            </li>
            <li>
              <a
                href="#program"
                className="link"
                onClick={(e) => handleLinkClick(e, 'program')}
              >
                <FormattedMessage id="header.link.program" />
              </a>
            </li>
            <li>
              <a
                href="#calendar"
                className="link"
                onClick={(e) => handleLinkClick(e, 'calendar')}
              >
                <FormattedMessage id="header.link.calendar" />
              </a>
            </li>
            <li>
              <a
                href="#requirements"
                className="link"
                onClick={(e) => handleLinkClick(e, 'requirements')}
              >
                <FormattedMessage id="header.link.requirements" />
              </a>
            </li>
            <li>
              <a
                href="#register"
                className="link link-subscribe"
                onClick={(e) => handleLinkClick(e, 'register')}
              >
                <FormattedMessage id="header.link.subscribe" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;