import adyen from 'src/static/svg/CompaniesLogos/logo_adyen.svg'
import alibaba from 'src/static/svg/CompaniesLogos/logo_alibaba.svg'
import amvos from 'src/static/svg/CompaniesLogos/logo_amvos.svg'
import atento from 'src/static/svg/CompaniesLogos/logo_atento.svg'
import aws from 'src/static/svg/CompaniesLogos/logo_aws.svg'
import biko from 'src/static/svg/CompaniesLogos/logo_biko.svg'
import binomio_ventures from 'src/static/svg/CompaniesLogos/logo_binomio-ventures.svg'
import brainy_commerce from 'src/static/svg/CompaniesLogos/logo_brainy-commerce.svg'
import cofnianza_online from 'src/static/svg/CompaniesLogos/logo_confianza-online.svg'
import cyberclik from 'src/static/svg/CompaniesLogos/logo_cyberclik.svg'
import dhl_express from 'src/static/svg/CompaniesLogos/logo_dhl-express.svg'
import digital_addiction from 'src/static/svg/CompaniesLogos/logo_digital-addiction.svg'
import ebay from 'src/static/svg/CompaniesLogos/logo_ebay.svg'
import garrigues from 'src/static/svg/CompaniesLogos/logo_garrigues.svg'
import good_rebels from 'src/static/svg/CompaniesLogos/logo_good-rebels.svg'
import google from 'src/static/svg/CompaniesLogos/logo_google.svg'
import howdazz from 'src/static/svg/CompaniesLogos/logo_howdazz.svg'
import innova_export from 'src/static/svg/CompaniesLogos/logo_innova-export.svg'
import interacso from 'src/static/svg/CompaniesLogos/logo_interacso.svg'
import iristrace from 'src/static/svg/CompaniesLogos/logo_iristrace.svg'
import lideres_digitales from 'src/static/svg/CompaniesLogos/logo_lideres-y-digitales.svg'
import metaverso_360 from 'src/static/svg/CompaniesLogos/logo_metaverso-360.svg'
import microsoft from 'src/static/svg/CompaniesLogos/logo_microsoft.svg'
import paycomet from 'src/static/svg/CompaniesLogos/logo_paycomet.svg'
import people4 from 'src/static/svg/CompaniesLogos/logo_people4.svg'
import proportione from 'src/static/svg/CompaniesLogos/logo_proportione.svg'
import roundcubers from 'src/static/svg/CompaniesLogos/logo_roundcubers.svg'
import sakudarte from 'src/static/svg/CompaniesLogos/logo_sakudarte.svg'
import sci_the_world from 'src/static/svg/CompaniesLogos/logo_sci-the-world.svg'
import sisteplant from 'src/static/svg/CompaniesLogos/logo_sisteplant.svg'
import sngular from 'src/static/svg/CompaniesLogos/logo_sngular.svg'
import telefonica from 'src/static/svg/CompaniesLogos/logo_telefonica.svg'
import ups from 'src/static/svg/CompaniesLogos/logo_ups.svg'

export default {
    adyen,
    alibaba,
    amvos,
    atento,
    aws,
    biko,
    binomio_ventures,
    brainy_commerce,
    cofnianza_online,
    cyberclik,
    dhl_express,
    digital_addiction,
    ebay,
    garrigues,
    good_rebels,
    google,
    howdazz,
    innova_export,
    interacso,
    iristrace,
    lideres_digitales,
    metaverso_360,
    microsoft,
    paycomet,
    people4,
    proportione,
    roundcubers,
    sakudarte,
    sci_the_world,
    sisteplant,
    sngular,
    telefonica,
    ups
}
